.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgba(69, 135, 200, 0.5);
}

.DateInput_input {
  font-size: 1.1rem;
  border-color: #e4e7e7;
  font-weight: bold;
}

.DateRangePicker_picker {
  z-index: 10;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span:hover {
  background-color: rgba(0, 126, 255, 0.5);
  color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #007eff;
  color: #fff;
}
