.fc .fc-button-primary {
  background-color: #3D5E77; /* blue */
  color: white;
  border: none;
  
}
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  background-color: #2a4152 ; /* Darker blue */
}
.fc .fc-button-primary:hover {
  background-color: #2a4152; /* Darker blue */
}
.fc .fc-toolbar-title {
  font-size: 24px;
}

.fc .fc-col-header-cell {
  background-color: #F6F8FA;
}